import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputText from '../../components/InputText.tsx';
import { StartContainer } from './components/StartContainer.tsx';
import { LoginContainer } from './components/LoginContainer.tsx';
import { InputArea } from './components/InputArea.tsx';
import Button from '../../components/Button.tsx';
import RubyText from '../../components/RubyText.tsx';
import axios from 'axios';
import { RightContainer } from './components/RightContainer/RightContainer.tsx';
import { Button as RightButton } from './components/RightContainer/Button.tsx';
import Message from '../../components/Message.tsx';
import Modal from '../../components/Modal.tsx';
import common from '../../common/common.ts';
import { s } from '@fullcalendar/core/internal-common';
import Loading from '../../components/Loading.tsx';
import styled from 'styled-components';

type LoginFormData = {
    StudentID: string;
    Password: string;
}

class ValidateMessage {
    StudentID: string;
    Password: string;
    Login: string;

    constructor() {
        this.StudentID = '';
        this.Password = '';
    }

    HasError(): boolean {
        return !!this.StudentID || !!this.Password || !!this.Login;
    }
}

/**
 * 入力値チェック処理
 */
function inputValidate(data: { StudentID: any; Password: any; }): ValidateMessage {

    const validate: ValidateMessage = new ValidateMessage();
    //ユーザー名必須チェック
    if (data.StudentID.length < 1) {
        validate.StudentID = '児童{じどう}IDは必{かなら}ず入力{にゅうりょく}してください。';
    }

    //パスワード桁数チェック
    if (data.Password.length < 1) {
        validate.Password = 'パスワードは必{かなら}ず入力{にゅうりょく}してください。';
    }

    return validate;
}
const LoagingSpinner = styled.div`
    &::before{
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-top-color: #5ac700;
        animation: spin 1s linear infinite;
        margin: 10px 10px;
    }
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
export default function Login() {

    const navigate = useNavigate();

    const [isLandScape, setIsLandScape] = useState(true);
    //画面のwidthとheightを取得する
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    //パスワード表示
    const [displayPassword, setDisplayPassword] = useState('');
    useEffect(() => {
        if (width > height) {
            setIsLandScape(true);
        }
        if (width < height) {
            setIsLandScape(false);
        }
    }, [width, height]);

    //画面の向きが変わったときに実行される
    window.addEventListener('resize', () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    });

    //フルスクリーンモードにする
    const goFullScreenAndLockOrientation = () => {
        // フルスクリーンモードにする
        const docEl = window.document.documentElement;

        const requestFullScreen = docEl.requestFullscreen;
        const lockOrientation = window.screen.orientation;

        if (requestFullScreen) {
            requestFullScreen.call(docEl);
        }

        // 画面の向きをランドスケープに固定する
        // if (lockOrientation) {
        //     lockOrientation.lock('landscape').catch(err => {
        //         console.error("Orientation lock not allowed:", err);
        //     });
        // }
    };

    const [startClicked, setStartClicked] = useState(false);
    //スタートボタン押下時
    const start = () => {
        setStartClicked(true);
        //フルスクリーンモードにする
        goFullScreenAndLockOrientation();

        
    }

    //ローカルストレージに格納しているユーザーIDを取得
    const getStudentID = () => {
        const studentID =  localStorage.getItem('StudentID');
        return studentID ? studentID : '';
    }
    
    //バリデーションメッセージ
    const [validateMessage, setValidateMessage] = useState<ValidateMessage>(new ValidateMessage());

    //ユーザーの入力したフォームデータ
    const [formData, setFormData] = useState({
        StudentID: getStudentID(),
        Password: '',
    });
    function handleInputChange(name: string, value: string) {
        //パスワードを表示
        if (name === 'Password') {
            setDisplayPassword(value);
        }
        setFormData({
            ...formData,
            [name]: value
        });
    }

    //ログイン状態を管理する
    const [isLogin, setIsLogin] = useState(false);
    //ログイン時メッセージ
    const [loginMessage, setLoginMessage] = useState('');

    function setLoginState(isLogin: boolean, message: string) {
        setIsLogin(isLogin);
        setLoginMessage(message);
    }

    const [isLoading, setIsLoading] = useState(false);
    //ログイン処理
    const handleSubmit = () => {
        
        //ログイン状態をリセット
        setLoginState(false, '');

        //nameがStudentID,Passwordの要素を取得
        const StudentID = document.getElementsByName('StudentID')[0] as HTMLInputElement;
        const Password = document.getElementsByName('Password')[0] as HTMLInputElement;
        let form = {
            StudentID: StudentID.value,
            Password: Password.value
        };
        const validate = inputValidate(form);

        //エラーがあれば処理終了
        if (validate.HasError()) {
            setValidateMessage(validate);
            return;
        }

        //ローディング表示
        setIsLoading(true);

        //ログイン処理
        //envファイルからAPIのURLを取得
        let query = {
            student_id: form.StudentID,
            password: form.Password
        }
        
        axios.post(process.env.REACT_APP_API_LOGIN as string, query)
            .then((response) => {
                setLoginState(true, 'ログインに成功{せいこう}しました');
                //セッションストレージにアクセストークンを保存
                sessionStorage.setItem("accessToken", response.data.access_token);
                console.log(response.data);
                //ニックネームが未登録または初期パスワードの場合はニックネーム登録画面へ遷移
                if (response.data.is_required_setting) {
                    navigate('/user-setting');
                } else {
                    //ニックネームが登録済みの場合はホーム画面へ遷移
                    navigate('/home');
                }
                //ローディング非表示
                setIsLoading(false);

                //ローカルストレージにユーザーIDとパスワードを保存
                localStorage.setItem('StudentID', form.StudentID);
                localStorage.setItem('Password', form.Password);
                //有効期限（本日より30日）を設定
                const expires = new Date();
                expires.setDate(expires.getDate() + 30);
                localStorage.setItem('expires', expires.toISOString());

            })
            .catch((error) => {
                setLoginState(false, 'ログインに失敗{しっぱい}しました');
                //ローディング非表示
                setIsLoading(false);
            });
    }
    //別タブで画面を開く
    function moveView(url: string) {
        //別タブで開く
        window.open(url, '_blank');
    }

    // モーダルの表示状態を管理するための状態変数
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalLoginHelp, setModalLoginHelp] = useState(false);
    const [modalInitiative, setModalInitiative] = useState(false);
    const [modalGuardian, setModalGuardian] = useState(false);
    const [modalGuide, setModalGuide] = useState(false);
    // モーダルを開く関数
    const openModal = () => {
        setIsModalOpen(true);
    };

    // モーダルを閉じる関数
    const closeModal = () => {
        setIsModalOpen(false);
    };

    //モーダルを表示する
    function setModal(modalName: string) {
        setModalLoginHelp(false);
        setModalInitiative(false);
        setModalGuardian(false);
        setModalGuide(false);
        switch (modalName) {
            case 'login':
                setModalLoginHelp(true);
                break;
            case 'initiative':
                setModalInitiative(true);
                break;
            case 'guardian':
                setModalGuardian(true);
                break;
            case 'guide':
                setModalGuide(true);
                break;
        }
        openModal();
    }

    return (
        <>
            {
                !startClicked &&
                <StartContainer>
                    {
                        isLandScape ?
                            <Button.Start className="pos-abs-bottom" onClick={start}></Button.Start>
                            :
                            <Message.Error><RubyText text="画面{がめん}の向{む}きを横向{よこむ}きにしてください" /></Message.Error>
                    }

                </StartContainer>
            }
            {
                startClicked &&

                <LoginContainer>
                    <h1>ログインする</h1>
                    {
                        //エラーメッセージがあれば表示
                        isLogin && loginMessage &&
                        <Message.Success><RubyText text={loginMessage}></RubyText></Message.Success>
                    }
                    {
                        //エラーメッセージがあれば表示
                        !isLogin && loginMessage &&
                        <Message.Error><RubyText text={loginMessage}></RubyText></Message.Error>
                    }

                    <InputArea>
                        <p><RubyText text="児童{じどう}IDとパスワードを入力{にゅうりょく}してログインボタンを押{お}してね"></RubyText></p>
                        <InputText
                            Label='児童{じどう}ID'
                            OnChangeText={(value) => handleInputChange('StudentID', value)}
                            Name='StudentID'
                            InitialValue={formData.StudentID}
                            Type='number'
                        />
                        <Message.ErrorLabel><RubyText text={validateMessage.StudentID}></RubyText></Message.ErrorLabel>

                        <InputText
                            Label='パスワード'
                            OnChangeText={(value) => handleInputChange('Password', value)}
                            Name='Password'
                            InitialValue={formData.Password}
                            Type='password'
                        />
                        <p style={{ marginTop: 0, marginLeft:'170px' , fontSize: '0.5rem' , textAlign:"end"}}>{displayPassword}</p>
                        <Message.ErrorLabel><RubyText text={validateMessage.Password}></RubyText></Message.ErrorLabel>
                    </InputArea>

                    <RightContainer>
                        <RightButton $img="/img/btn-side__L5.png" onClick={() => setModal('initiative')}></RightButton>
                        <RightButton $img="/img/btn-side__L6.png" onClick={() => setModal('guardian')}></RightButton>
                        <RightButton $img="/img/btn-side__L7.png" onClick={() => setModal('guide')}></RightButton>
                        <RightButton $img="/img/btn-side__L10.png" onClick={() => moveView('https://forms.office.com/Pages/ResponsePage.aspx?id=gqFmy1a1NUajZdJO56f3g1btiuaUuWxHoxJgwg67VmBUMkc1MkFaMTlXWFc3TEg1OUFMSVVNUFMyQy4u')}></RightButton>
                    </RightContainer>
                    {
                        isLoading ? (
                            <LoagingSpinner></LoagingSpinner>
                        ):
                        <Button.Login onClick={handleSubmit}></Button.Login>
                        
                    }
                    
                    <Button.Default onClick={() => setModal('login')} className="small"><RubyText text="ログインできないとき"></RubyText></Button.Default>

                    {isModalOpen && (
                        <Modal>
                            <>
                                {modalLoginHelp && (
                                    <>
                                        <h1><RubyText text="ログインできないとき"></RubyText></h1>
                                        <p style={{ "marginTop": "20px" }}><RubyText text="ログインできないときは配布{はいふ}された登録{とうろく}内容{ないよう}通知書{つうちしょ}を確認{かくにん}しよう！"></RubyText></p>
                                        <p style={{ "marginTop": "20px" }}><RubyText text="パスワードが分{わ}からないときは、担任{たんにん}の先生{せんせい}に聞{き}きましょう！"></RubyText></p>
                                    </>
                                )}
                                {modalInitiative && (
                                    <>
                                        <iframe style={{ "width": "100%", "height": "80%" }} src="https://www.pref.fukui.lg.jp/doc/055515/index.html" />
                                    </>
                                )}
                                {modalGuardian && (
                                    <>
                                        <embed src='https://drive.google.com/viewerng/viewer?embedded=true&url=https://hapisupo.pref.fukui.lg.jp/assets/pdf/Guardian.pdf#toolbar=0&navpanes=0' width='100%' height='80%'></embed>
                                    </>
                                )}
                                {modalGuide && (
                                    <>
                                        <object data='https://drive.google.com/viewerng/viewer?embedded=true&url=https://hapisupo.pref.fukui.lg.jp/assets/pdf/Guide.pdf#toolbar=0&navpanes=0' width='100%' height='80%'></object>
                                    </>
                                )}
                                <Button.Default className="modal-close" onClick={closeModal}>閉じる</Button.Default>
                            </>
                        </Modal>
                    )}
                </LoginContainer>
            }
        </>

    )
}